body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aqua;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sign-in-container{
  padding: 20px;
}

.appbar-logo{
  height: 16px;
  margin-right: 10px;
}

.data-grid-running{
  background-color: #c0f9b4;
  color: #048a1f;
}

.data-grid-failed{
  background-color: #FFEBEB;
  color: #AD0000
}

.data-grid-passed{
  background-color: white;
}